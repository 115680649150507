<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" v-if="isMainInstance">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <b-link class="brand-logo brand-logo-full" v-else>
        <b-img :src="require('@/assets/images/logo/logo-v2.png')" alt="logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            Подтвердите ваш email ✔️
          </b-card-title>
          <b-card-text class="mb-2">
            На почту ({{ getUserEmail }}) выслана ссылка, по которой вы сможете
            подтвердить ваш Email.
            <br />
            Если вы уже подтвердили Email, попробуйте обновить старницу
          </b-card-text>

          <!-- form -->
          <b-button
            variant="primary"
            block
            @click="resendEmail"
            :disabled="!isResendActive"
          >
            {{ !isResendActive ? countdownSec : "" }} Отправить повторно
          </b-button>

          <p class="text-center mt-2">
            <b-link @click="logout()">
              <feather-icon icon="ChevronLeftIcon" /> Выйти
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import firebase from "firebase/compat/app";
import { initialAbility } from "@/libs/acl/config";

import { fb } from "@/main";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      isResendActive: false,
      countdownSec: 60,
    };
  },
  metaInfo() {
    return {
      title: "moovee - Восстановление пароля",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    getUserEmail() {
      let userEmail = JSON.parse(localStorage.getItem("userData")).user.email;
      const hide = userEmail.split("@")[0].length - 4;
      var r = new RegExp(".{" + hide + "}@", "g");
      userEmail = userEmail.replace(r, "***@");
      return userEmail;
    },
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  methods: {
    resendEmail() {
      const user = fb.auth().currentUser;
      user.sendEmailVerification().then(() => {
        this.isResendActive = false;
        let countdown = setInterval(
          function() {
            this.countdownSec = this.countdownSec - 1;
            if (this.countdownSec == 0) {
              this.countdownSec = 60;
              this.isResendActive = true;
              clearInterval(countdown);
            }
          }.bind(this),
          1000
        );
      });
    },
    logout() {
      localStorage.removeItem("userData");
      this.$ability.update(initialAbility);
      fb.auth().signOut();
      this.$router.go({ name: "auth-login" });
    },
  },
  created() {
    let countdown = setInterval(
      function() {
        this.countdownSec = this.countdownSec - 1;
        if (this.countdownSec == 0) {
          this.countdownSec = 60;
          this.isResendActive = true;
          clearInterval(countdown);
        }
      }.bind(this),
      1000
    );
    firebase.auth().onAuthStateChanged(
      function(user) {
        if (user.emailVerified) {
          this.$router.push("/");
        }
      }.bind(this)
    );
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
